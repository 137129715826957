Sendage.API = {};

(function($) {
	var API = Sendage.API;
	var promises = {};
	var currentEditingSendlist = null;

	// Make the Sendage.API an Observable
	$.extend(API, PubSub);
	
	// TODO add triggers to allow other items interested in listening to API events to handle return data. 
	function request(action, data) {
		return $.ajax({
			url: '/api/' + action,
			method: 'POST',
			type: 'POST',	// jquery <1.9
			dataType: 'json',
			data: data
		});
	}

	function requestOnce(action, data) {
		if (!promises[action]) {
			promises[action] = request(action, data);
		}
		return promises[action];
	}
	
	API.climbs = function(state) {
		return request('climbs', state);
	}
	
	API.acClimbs = function(term, areas, excludeClimbId) {
		return API.climbs({
			term: term,
			limit: 15,
			order: ['sends DESC'],
			areas: areas ? areas : [],
			area_parents: true,
			exclude: excludeClimbId ? excludeClimbId : 0,
			include_area_parents: true,
		}).pipe(function(data) {								// TODO jquery > 1.9 use .then instead of .pipe
			for (var i=0; i<data.climbs.length; i++) {
				var item = data.climbs[i];
				item.Area = findArea(data.areas, item.Climb.area_id);
				item.Climb.sends = item[0].sends;
				item.Climb.rating = item[0].rating;
				item.Climb.send_type = item[0].send_type;
			}
			return data.climbs;
		});
	}
	
	API.acAreas = function(term) {
		return request('acAreas', {term: term});
	}

	/**
	  * @param int sendlistId
	  */
	API.deleteSendlist = function(sendlistId) {
		return request('deleteSendlist', {sendlistId: sendlistId}).done(function(sendlist) {
			currentEditingSendlist = [];
			API.publish('sendlist.editing.changed', []);
		});
	}

	/**
	  * @param int sendlistId If 0, create a new list
	  */
	API.editSendlist = function(sendlistId) {
		return request('editSendlist', {sendlistId: sendlistId}).done(function(sendlist) {
			currentEditingSendlist = sendlist;
			API.publish('sendlist.editing.changed', sendlist);
			API.publish('sendlist.editing.climbs.changed', sendlist);
		});
	}

	API.editSendlistComplete = function() {
		return request('editSendlistComplete', {}).done(function(result) {
			currentEditingSendlist = [];
			API.publish('sendlist.editing.changed', []);
		});
	}

	API.saveSendlistMeta = function(sendlistId, name, description, published) {
		return request('saveSendlistMeta', {sendlistId: sendlistId, name: name, description: description, published:published?1:0}).done(function(sendlist) {
			currentEditingSendlist = sendlist;
			API.publish('sendlist.editing.changed', sendlist);
		});		
	}
	
	/**
	  * Get the list that is currently being edited
	  */
	API.getEditingSendlist = function() {		
		return currentEditingSendlist !== null ? $.Deferred().resolve(currentEditingSendlist) : requestOnce('getEditingSendlist', {});
	}

	/**
	 * @param int sendlistId 0 if adding to new or editing list. If not currently editing a list, will create a new pending list. 
	 * @param int climbId
	 */
	API.addToSendlist = function(sendlistId, climbId) {
		return request('addToSendlist', {sendlistId: sendlistId, climbId: climbId}).done(function(sendlist) {
			if (!sendlist.error) { 
				currentEditingSendlist = sendlist;
				API.publish('sendlist.editing.climbs.changed', sendlist);
			}
		});
	}

	/**
	 * @param int sendlistId 
	 * @param int climbId
	 */
	API.removeFromSendlist = function(sendlistId, climbId) {
		return request('removeFromSendlist', {sendlistId: sendlistId, climbId: climbId}).done(function(sendlist) {
			if (!sendlist.error) {
				currentEditingSendlist = sendlist;
				API.publish('sendlist.editing.climbs.changed', sendlist);
			}
		});	
	}

	API.adminToggleUserDisabled = function(userId) {
		return request('adminToggleUserDisabled', {userId: userId});	
	}
	
	API.adminToggleAreaFlag = function(areaId, flag) {
		return request('adminToggleAreaFlag', {	areaId: areaId, flag: flag });		
	}
	
	API.adminRemoveClimb = function(climbId) {
		return request('adminRemoveClimb', { climbId: climbId });
	}
	
	API.adminResolveKarma = function(resolution, karmaId, answerId) {
		return request('adminResolveKarma', { resolution: resolution, karmaId: karmaId, answerId: answerId });	
	}
	
	API.adminResolveTrustedKarma = function() {
		return request('adminResolveTrustedKarma', {});		
	}

	API.adminResolveNoneOfTheAboveKarma = function() {
		return request('adminResolveNoneOfTheAboveKarma', {});		
	}

	var UI = {
		adminToggleUserDisabled: function(data) {
			var self = this;
			API.adminToggleUserDisabled(data.userId).done(function(result) {
				if (data.noRefresh != "1") {
					handleGenericSuccessAsReload(result);
				} else {
					$(self).html(result.User.disabled ? 'Enable' : 'Disable');
				}
			});
		},
		
		adminToggleAreaFlag: function(data) {
			API.adminToggleAreaFlag(data.areaId, data.flag).done(function(result) {
				handleGenericSuccessAsReload(result);
			});
		},
		
		adminRemoveClimb: function(data) {
			API.adminRemoveClimb(data.climbId).done(function(result) {
				handleGenericSuccessAsRedirect(result);
			});
		},
		
		adminResolveKarma: function(data) {
			var a = $(this);
			API.adminResolveKarma(data.resolution, data.karmaId, data.answerId).done(function(result) {
				// markup in View/Admin/karma.ctp
				var table = a.closest("table");
				a.closest("tr").remove();
				if (table.find("tr").length==0) {
					table.closest("tr").remove();
				}
			});
		},
		
		adminResolveTrustedKarma: function(data) {
			API.adminResolveTrustedKarma().done(function(result) {
				handleGenericSuccessAsReload(result);
			});
		},

		adminResolveNoneOfTheAboveKarma: function(data) {
			API.adminResolveNoneOfTheAboveKarma().done(function(result) {
				handleGenericSuccessAsReload(result);
			});
		}
		
	};
		
	
	// Generic handler for invoking clickable API calls. 
	$('.api-action').on('click', function(evt) {
		evt.preventDefault();
		var data = $(this).data();
		var action = data.apiAction;

		if (!data.confirm || confirm('Are you sure?')) {
			if (UI[action]) {
				UI[action].call(this, data);
			}
		}
	});
	
	// Handlers for UI api calls. 	
	function handleGenericError(result) {
		if (result.error) {
			alert('ERROR: '+result.error);
			return true;
		}
		return false;
	}
	
	function handleGenericSuccessAsDoNothing(result) {
		if (!handleGenericError(result)) {
			console.log(result);
			// do nothing
		}
	}

	function handleGenericSuccessAsReload(result) {
		if (!handleGenericError(result)) {
			location.reload();
		}
	}

	function handleGenericSuccessAsRedirect(result) {
		if (!handleGenericError(result)) {
			if (result.redirect) {
				window.location = result.redirect;
			}
		}
	}
	
	function findArea(areas, areaId) {
		for (var i=0; i<areas.length; i++) {
			if (areas[i].Area.id == areaId) {
				return areas[i].Area;
			}
		}
		return null;
	}
	
	
	
})(jQuery);