jQuery.fn.filterOptions = function(settings, handler) {
	return this.each(function() {
		var select = this;

		if (!$(select).data("options"))
		{
			var options = [];
			$(select).find('option').each(function() {
				options.push({value: $(this).val(), text: $(this).text()});
			});
			$(select).data('options', options);
		}

		var curVal = $(select).val();
		var options = $(select).empty().data("options");
		$.each(options, function(i) {
			var option = options[i];
			if(handler(option.value, settings)) {
				$(select).append(
					$('<option>').text(option.text).val(option.value).attr("selected",curVal==option.value)
				);
			}
		});
	});
};

$.ajaxSetup({
	dataType: "json",
	timeout: 10000
});

// var API = {};

var Trophy = {
	toHtml: function(type, fa, withLabel, hideBadge) {
		var html = [];
		
		if (type=='repeat') {
			html.push("<i class='repeat fa fa-icon fa-check-circle-o'></i>");
		} else if (type=='project') {
			html.push("<i class='project fa fa-icon fa-square-o'></i>");
		} else {
			html.push("<span class='trophy fa-stack "+type+" "+(fa?'first-ascent':'')+"'>");
				html.push("<i class='fa fa-trophy fa-stack-2x'></i>");
				if (!hideBadge) {
					var check = 'fa-check-circle';
					if (type=='repeat') {
						check = check += '-o';
					} else if (type == 'project') {
						check = 'fa-circle';
					}
					html.push("<i class='fa fa-icon fa-star fa-stack-1x'></i>");
					html.push("<i class='fa fa-icon "+check+" fa-stack-1x'></i>");	
				}
				if (withLabel) {
					html.push("<label>"+type+"</label>");
				}
			html.push("</span>");
		}
		return html.join('');
	}	
};

// taken from http://lehelk.com/2011/05/06/script-to-remove-diacritics/
var defaultDiacriticsRemovalMap = [
    {'base':'A', 'letters':/[\u0041\u24B6\uFF21\u00C0\u00C1\u00C2\u1EA6\u1EA4\u1EAA\u1EA8\u00C3\u0100\u0102\u1EB0\u1EAE\u1EB4\u1EB2\u0226\u01E0\u00C4\u01DE\u1EA2\u00C5\u01FA\u01CD\u0200\u0202\u1EA0\u1EAC\u1EB6\u1E00\u0104\u023A\u2C6F]/g},
    {'base':'AA','letters':/[\uA732]/g},
    {'base':'AE','letters':/[\u00C6\u01FC\u01E2]/g},
    {'base':'AO','letters':/[\uA734]/g},
    {'base':'AU','letters':/[\uA736]/g},
    {'base':'AV','letters':/[\uA738\uA73A]/g},
    {'base':'AY','letters':/[\uA73C]/g},
    {'base':'B', 'letters':/[\u0042\u24B7\uFF22\u1E02\u1E04\u1E06\u0243\u0182\u0181]/g},
    {'base':'C', 'letters':/[\u0043\u24B8\uFF23\u0106\u0108\u010A\u010C\u00C7\u1E08\u0187\u023B\uA73E]/g},
    {'base':'D', 'letters':/[\u0044\u24B9\uFF24\u1E0A\u010E\u1E0C\u1E10\u1E12\u1E0E\u0110\u018B\u018A\u0189\uA779]/g},
    {'base':'DZ','letters':/[\u01F1\u01C4]/g},
    {'base':'Dz','letters':/[\u01F2\u01C5]/g},
    {'base':'E', 'letters':/[\u0045\u24BA\uFF25\u00C8\u00C9\u00CA\u1EC0\u1EBE\u1EC4\u1EC2\u1EBC\u0112\u1E14\u1E16\u0114\u0116\u00CB\u1EBA\u011A\u0204\u0206\u1EB8\u1EC6\u0228\u1E1C\u0118\u1E18\u1E1A\u0190\u018E]/g},
    {'base':'F', 'letters':/[\u0046\u24BB\uFF26\u1E1E\u0191\uA77B]/g},
    {'base':'G', 'letters':/[\u0047\u24BC\uFF27\u01F4\u011C\u1E20\u011E\u0120\u01E6\u0122\u01E4\u0193\uA7A0\uA77D\uA77E]/g},
    {'base':'H', 'letters':/[\u0048\u24BD\uFF28\u0124\u1E22\u1E26\u021E\u1E24\u1E28\u1E2A\u0126\u2C67\u2C75\uA78D]/g},
    {'base':'I', 'letters':/[\u0049\u24BE\uFF29\u00CC\u00CD\u00CE\u0128\u012A\u012C\u0130\u00CF\u1E2E\u1EC8\u01CF\u0208\u020A\u1ECA\u012E\u1E2C\u0197]/g},
    {'base':'J', 'letters':/[\u004A\u24BF\uFF2A\u0134\u0248]/g},
    {'base':'K', 'letters':/[\u004B\u24C0\uFF2B\u1E30\u01E8\u1E32\u0136\u1E34\u0198\u2C69\uA740\uA742\uA744\uA7A2]/g},
    {'base':'L', 'letters':/[\u004C\u24C1\uFF2C\u013F\u0139\u013D\u1E36\u1E38\u013B\u1E3C\u1E3A\u0141\u023D\u2C62\u2C60\uA748\uA746\uA780]/g},
    {'base':'LJ','letters':/[\u01C7]/g},
    {'base':'Lj','letters':/[\u01C8]/g},
    {'base':'M', 'letters':/[\u004D\u24C2\uFF2D\u1E3E\u1E40\u1E42\u2C6E\u019C]/g},
    {'base':'N', 'letters':/[\u004E\u24C3\uFF2E\u01F8\u0143\u00D1\u1E44\u0147\u1E46\u0145\u1E4A\u1E48\u0220\u019D\uA790\uA7A4]/g},
    {'base':'NJ','letters':/[\u01CA]/g},
    {'base':'Nj','letters':/[\u01CB]/g},
    {'base':'O', 'letters':/[\u004F\u24C4\uFF2F\u00D2\u00D3\u00D4\u1ED2\u1ED0\u1ED6\u1ED4\u00D5\u1E4C\u022C\u1E4E\u014C\u1E50\u1E52\u014E\u022E\u0230\u00D6\u022A\u1ECE\u0150\u01D1\u020C\u020E\u01A0\u1EDC\u1EDA\u1EE0\u1EDE\u1EE2\u1ECC\u1ED8\u01EA\u01EC\u00D8\u01FE\u0186\u019F\uA74A\uA74C]/g},
    {'base':'OI','letters':/[\u01A2]/g},
    {'base':'OO','letters':/[\uA74E]/g},
    {'base':'OU','letters':/[\u0222]/g},
    {'base':'P', 'letters':/[\u0050\u24C5\uFF30\u1E54\u1E56\u01A4\u2C63\uA750\uA752\uA754]/g},
    {'base':'Q', 'letters':/[\u0051\u24C6\uFF31\uA756\uA758\u024A]/g},
    {'base':'R', 'letters':/[\u0052\u24C7\uFF32\u0154\u1E58\u0158\u0210\u0212\u1E5A\u1E5C\u0156\u1E5E\u024C\u2C64\uA75A\uA7A6\uA782]/g},
    {'base':'S', 'letters':/[\u0053\u24C8\uFF33\u1E9E\u015A\u1E64\u015C\u1E60\u0160\u1E66\u1E62\u1E68\u0218\u015E\u2C7E\uA7A8\uA784]/g},
    {'base':'T', 'letters':/[\u0054\u24C9\uFF34\u1E6A\u0164\u1E6C\u021A\u0162\u1E70\u1E6E\u0166\u01AC\u01AE\u023E\uA786]/g},
    {'base':'TZ','letters':/[\uA728]/g},
    {'base':'U', 'letters':/[\u0055\u24CA\uFF35\u00D9\u00DA\u00DB\u0168\u1E78\u016A\u1E7A\u016C\u00DC\u01DB\u01D7\u01D5\u01D9\u1EE6\u016E\u0170\u01D3\u0214\u0216\u01AF\u1EEA\u1EE8\u1EEE\u1EEC\u1EF0\u1EE4\u1E72\u0172\u1E76\u1E74\u0244]/g},
    {'base':'V', 'letters':/[\u0056\u24CB\uFF36\u1E7C\u1E7E\u01B2\uA75E\u0245]/g},
    {'base':'VY','letters':/[\uA760]/g},
    {'base':'W', 'letters':/[\u0057\u24CC\uFF37\u1E80\u1E82\u0174\u1E86\u1E84\u1E88\u2C72]/g},
    {'base':'X', 'letters':/[\u0058\u24CD\uFF38\u1E8A\u1E8C]/g},
    {'base':'Y', 'letters':/[\u0059\u24CE\uFF39\u1EF2\u00DD\u0176\u1EF8\u0232\u1E8E\u0178\u1EF6\u1EF4\u01B3\u024E\u1EFE]/g},
    {'base':'Z', 'letters':/[\u005A\u24CF\uFF3A\u0179\u1E90\u017B\u017D\u1E92\u1E94\u01B5\u0224\u2C7F\u2C6B\uA762]/g},
    {'base':'a', 'letters':/[\u0061\u24D0\uFF41\u1E9A\u00E0\u00E1\u00E2\u1EA7\u1EA5\u1EAB\u1EA9\u00E3\u0101\u0103\u1EB1\u1EAF\u1EB5\u1EB3\u0227\u01E1\u00E4\u01DF\u1EA3\u00E5\u01FB\u01CE\u0201\u0203\u1EA1\u1EAD\u1EB7\u1E01\u0105\u2C65\u0250]/g},
    {'base':'aa','letters':/[\uA733]/g},
    {'base':'ae','letters':/[\u00E6\u01FD\u01E3]/g},
    {'base':'ao','letters':/[\uA735]/g},
    {'base':'au','letters':/[\uA737]/g},
    {'base':'av','letters':/[\uA739\uA73B]/g},
    {'base':'ay','letters':/[\uA73D]/g},
    {'base':'b', 'letters':/[\u0062\u24D1\uFF42\u1E03\u1E05\u1E07\u0180\u0183\u0253]/g},
    {'base':'c', 'letters':/[\u0063\u24D2\uFF43\u0107\u0109\u010B\u010D\u00E7\u1E09\u0188\u023C\uA73F\u2184]/g},
    {'base':'d', 'letters':/[\u0064\u24D3\uFF44\u1E0B\u010F\u1E0D\u1E11\u1E13\u1E0F\u0111\u018C\u0256\u0257\uA77A]/g},
    {'base':'dz','letters':/[\u01F3\u01C6]/g},
    {'base':'e', 'letters':/[\u0065\u24D4\uFF45\u00E8\u00E9\u00EA\u1EC1\u1EBF\u1EC5\u1EC3\u1EBD\u0113\u1E15\u1E17\u0115\u0117\u00EB\u1EBB\u011B\u0205\u0207\u1EB9\u1EC7\u0229\u1E1D\u0119\u1E19\u1E1B\u0247\u025B\u01DD]/g},
    {'base':'f', 'letters':/[\u0066\u24D5\uFF46\u1E1F\u0192\uA77C]/g},
    {'base':'g', 'letters':/[\u0067\u24D6\uFF47\u01F5\u011D\u1E21\u011F\u0121\u01E7\u0123\u01E5\u0260\uA7A1\u1D79\uA77F]/g},
    {'base':'h', 'letters':/[\u0068\u24D7\uFF48\u0125\u1E23\u1E27\u021F\u1E25\u1E29\u1E2B\u1E96\u0127\u2C68\u2C76\u0265]/g},
    {'base':'hv','letters':/[\u0195]/g},
    {'base':'i', 'letters':/[\u0069\u24D8\uFF49\u00EC\u00ED\u00EE\u0129\u012B\u012D\u00EF\u1E2F\u1EC9\u01D0\u0209\u020B\u1ECB\u012F\u1E2D\u0268\u0131]/g},
    {'base':'j', 'letters':/[\u006A\u24D9\uFF4A\u0135\u01F0\u0249]/g},
    {'base':'k', 'letters':/[\u006B\u24DA\uFF4B\u1E31\u01E9\u1E33\u0137\u1E35\u0199\u2C6A\uA741\uA743\uA745\uA7A3]/g},
    {'base':'l', 'letters':/[\u006C\u24DB\uFF4C\u0140\u013A\u013E\u1E37\u1E39\u013C\u1E3D\u1E3B\u017F\u0142\u019A\u026B\u2C61\uA749\uA781\uA747]/g},
    {'base':'lj','letters':/[\u01C9]/g},
    {'base':'m', 'letters':/[\u006D\u24DC\uFF4D\u1E3F\u1E41\u1E43\u0271\u026F]/g},
    {'base':'n', 'letters':/[\u006E\u24DD\uFF4E\u01F9\u0144\u00F1\u1E45\u0148\u1E47\u0146\u1E4B\u1E49\u019E\u0272\u0149\uA791\uA7A5]/g},
    {'base':'nj','letters':/[\u01CC]/g},
    {'base':'o', 'letters':/[\u006F\u24DE\uFF4F\u00F2\u00F3\u00F4\u1ED3\u1ED1\u1ED7\u1ED5\u00F5\u1E4D\u022D\u1E4F\u014D\u1E51\u1E53\u014F\u022F\u0231\u00F6\u022B\u1ECF\u0151\u01D2\u020D\u020F\u01A1\u1EDD\u1EDB\u1EE1\u1EDF\u1EE3\u1ECD\u1ED9\u01EB\u01ED\u00F8\u01FF\u0254\uA74B\uA74D\u0275]/g},
    {'base':'oi','letters':/[\u01A3]/g},
    {'base':'ou','letters':/[\u0223]/g},
    {'base':'oo','letters':/[\uA74F]/g},
    {'base':'p','letters':/[\u0070\u24DF\uFF50\u1E55\u1E57\u01A5\u1D7D\uA751\uA753\uA755]/g},
    {'base':'q','letters':/[\u0071\u24E0\uFF51\u024B\uA757\uA759]/g},
    {'base':'r','letters':/[\u0072\u24E1\uFF52\u0155\u1E59\u0159\u0211\u0213\u1E5B\u1E5D\u0157\u1E5F\u024D\u027D\uA75B\uA7A7\uA783]/g},
    {'base':'s','letters':/[\u0073\u24E2\uFF53\u00DF\u015B\u1E65\u015D\u1E61\u0161\u1E67\u1E63\u1E69\u0219\u015F\u023F\uA7A9\uA785\u1E9B]/g},
    {'base':'t','letters':/[\u0074\u24E3\uFF54\u1E6B\u1E97\u0165\u1E6D\u021B\u0163\u1E71\u1E6F\u0167\u01AD\u0288\u2C66\uA787]/g},
    {'base':'tz','letters':/[\uA729]/g},
    {'base':'u','letters':/[\u0075\u24E4\uFF55\u00F9\u00FA\u00FB\u0169\u1E79\u016B\u1E7B\u016D\u00FC\u01DC\u01D8\u01D6\u01DA\u1EE7\u016F\u0171\u01D4\u0215\u0217\u01B0\u1EEB\u1EE9\u1EEF\u1EED\u1EF1\u1EE5\u1E73\u0173\u1E77\u1E75\u0289]/g},
    {'base':'v','letters':/[\u0076\u24E5\uFF56\u1E7D\u1E7F\u028B\uA75F\u028C]/g},
    {'base':'vy','letters':/[\uA761]/g},
    {'base':'w','letters':/[\u0077\u24E6\uFF57\u1E81\u1E83\u0175\u1E87\u1E85\u1E98\u1E89\u2C73]/g},
    {'base':'x','letters':/[\u0078\u24E7\uFF58\u1E8B\u1E8D]/g},
    {'base':'y','letters':/[\u0079\u24E8\uFF59\u1EF3\u00FD\u0177\u1EF9\u0233\u1E8F\u00FF\u1EF7\u1E99\u1EF5\u01B4\u024F\u1EFF]/g},
    {'base':'z','letters':/[\u007A\u24E9\uFF5A\u017A\u1E91\u017C\u017E\u1E93\u1E95\u01B6\u0225\u0240\u2C6C\uA763]/g}
];
function removeDiacritics (str) 
{
	var changes = defaultDiacriticsRemovalMap;
	for(var i=0; i<changes.length; i++)
		str = str.replace(changes[i].letters, changes[i].base);
	return str;
}

function parseDate(day) {
	var parts = day.split("-");
	return new Date(parseInt(parts[0],10), parseInt(parts[1],10)-1, parseInt(parts[2],10));	
}

function areaWithParents(area, separator, linked) {
	var areaList = [];
	var s;
	if (area.parents) {
		for (var i=0; i<area.parents.length; i++) {
			var parentArea = area.parents[i];
			s = parentArea.name;
			if (linked) {
				s = "<a href='/area/"+parentArea.slug+"'>"+s+"</a>";
			}
			areaList.push(s);
		}
	}
	s = area.name;
	if (linked) {
		s = "<a href='/area/"+area.slug+"'>"+s+"</a>";
	}
	areaList.push(s);
	return areaList.join(separator);
}

var Sendlist = function(containerID, config)
{
	var me = this;
	this.containerID = containerID;
	this.element = $("#"+containerID);
	
	this.id = this.element.attr("sendlist_id");
	this.parent_id = this.element.attr("parent_id");
	this.uniqid = this.element.attr("sendlist_uniqid");
	this.name = this.element.attr("sendlist_name");
	this.description = this.element.attr("sendlist_description");
	var editable = this.element.attr("editable") == "true";

	$.extend(this.config, config);

	var grades = Sendlist.Grade.rawdata;

	var climbs = this.element.attr("climbs");
	var areas = this.element.attr("areas");
	
	if (areas)
		areas = JSON.parse(unescape(areas));
	
	if (climbs)
	{
		climbs = JSON.parse(unescape(climbs));

		for (var i=0; i<climbs.length; i++)
		{
			var c = climbs[i];
			grade = c.Climb.Grade ? c.Climb.Grade : grades[c.Climb.grade_id];
			area = c.Climb.Area ? c.Climb.Area : areas[c.Climb.area_id];

			// TODO WHY?
			if (c.UserClimb && c.Grade)
			{
				c.UserClimb.Grade = c.Grade;
				delete c.Grade;
			}
			
			if (!c.UserClimb.Grade)
				c.UserClimb.Grade = grades[c.UserClimb.grade_id];
			
			
			var sc = new SendlistClimb(c.SendlistClimb?c.SendlistClimb.id:(c.id?c.id:null), this, c.Climb, grade, area, c.UserClimb, editable);
			this.climbs.push(sc);
		}

		if (this.config.isBeingCreated && !this.config.pageContainerId)
			this.pageState.size = climbs.length;
		
		
		if (this.config.autoShowMore)
		{
			$(window).scroll(function() {
				// add 200 pixels because of comments below. could probably even add more...
				if  ($(window).scrollTop() >= $("#"+containerID).height()-$(window).height()+200) {
					me._handleShowMore();
				}
			});
		}
	}


	this.renderSort();
	this.renderFilter();
	this.renderSearch();
	this.render();
	this.renderShowMore();
};

Sendlist.Grade = Sendage.Grade;

Sendlist.prototype = {
	id: null,
	uniqid: "",
	name: "",
	description: "",
	climbs: [],				//TODO: optimize? store climbs[] as climbs{} and index by climb.id for faster lookups
	containerID: "",
	element: null,
	filterFunction: null,
	filterFunctionPersist: false,
	showingMore: false, 
	config: {
		maxClimbs: 200,
		autoShowMore: false,
		sortContainerId: null,
		filterContainerId: null,
		pageContainerId: null,
		isBeingCreated: false,
		showRemoveConfirmation: true
	},
	
	pageState: {
		filter: "all",
		sortBy:"", 
		index: 0, 
		size: 25
	},
	
	pendingCommentCounts: [],

	// insert the given SendlistClimb object
	// return false if the climb wasn't added (duplicate or hit max size)
	insert: function(climb, index) {
		var doAdd = this.climbs.length<this.config.maxClimbs && this._getClimbIndex(climb)===false;

		if (doAdd)
		{
			this.climbs.splice(index,0,climb);
			this._throwResizedEvent();
		}

		return doAdd;		
	},
	
	add: function(climb) {
		return this.insert(climb, this.climbs.length);
	},

	// remove the given SendlistClimb object
	remove: function(climb) {
		// remove the climb from climbs[]
		var i = this._getClimbIndex(climb);
		if (i !== false)		// found a climb in the list - remove it
			this.climbs.splice(i,1);
			
		// remove the climb from DOM
		climb.remove();	

		this._throwResizedEvent();
	},

	clear: function() {
		for (var i=0; i<this.climbs.length; i++)
			this.climbs[i].remove();
		this.climbs = [];
		this._throwResizedEvent();
	},

	count: function() {
		return this.climbs.length;
	},

	contains: function(climb) {
		return this._getClimbIndex(climb) !== false;	
	},
	
	save: function() {
		if (this.climbs.length > 0)
		{
			// prepare CakePHP data
			var data = {};
			data["data[Sendlist][id]"] = this.id;
			data["data[Sendlist][uniqid]"] = this.uniqid;
			data["data[Sendlist][name]"] = this.name;
			data["data[Sendlist][description]"] = this.description;
			data["data[SendlistClimb]"] = JSON.stringify(this._getNewClimbData());
			
			$.ajax({
				url: "/sendlists/save",
				type: "POST",
				data: data,
				success: function(data) {
					if (!data.error)
					{
						if (data.id)
							window.location = "/sendlists/view/"+data.id;
						else
							alert("error: no id!");
					}
					else
						alert("error: "+data.error);
				}
			});
		
		}
	},

	passFilter: function(filter, climb)
	{
		if (this.filterFunction) {
			return this.filterFunction(filter, climb);
		} else {
			return 	filter=="all" || 
					(filter=="routes" && climb.isRoute()) || 
					(filter=="boulders" && climb.isBoulder()) || 
					(filter=="first_ascent" && climb.isFirstAscent()) ||
					(filter=="project" && climb.user_climb && climb.user_climb.type=="project");
		}
	},
	
	

	// render the entire list
	render: function(softRender) {
		if (!softRender)
		{
			this.element.empty();
			$("#"+this.config.pageContainerId).css("visibility", "visible");
		}
			
		var ps = this.pageState;
		var j = 0;
		var numFiltered = 0;
		var numShown = 0;
		var climbs = this.climbs;
		var climbsLength = climbs.length;
		var filter = ps.filter;
		var startIndex = ps.index;
		
		for (var i=0; i<climbsLength; i++)
		{
			var c = climbs[i];
			var passFilter = this.passFilter(filter, c);
			
			if (passFilter && j>=startIndex && (!this.config.pageContainerId || numShown<ps.size))
			{
				c.render(this.containerID);
				numShown++;
			}
			
			if (passFilter)
			{
				j++;
				numFiltered++;
			}
			
			if (numShown >= ps.size)
				break;
			
		}
		
		Sendage.API.publish('toggle-to-sendlist.reloaded', true);

		if (numShown < ps.size)
			$("#"+this.config.pageContainerId).css("visibility", "hidden");

		this.pageState.numItems = numFiltered;
		this._updateBorderClass();
		this.fetchCommentCounts();
	},
	
	fetchCommentCounts: function() {
		var url = 'https://sendage.disqus.com/count-data.js?q=1';
		for (var i=0; i<this.pendingCommentCounts.length; i++) {
			var id = this.pendingCommentCounts[i];
			var uc = 'http://'+document.location.host+'/user_climbs/view/'+id;
			url += "&"+id+"=2,"+uc;
		}
		
		this.pendingCommentCounts = [];
		var head = document.getElementsByTagName("HEAD")[0] || document.body;
		var s = document.createElement("script");
		s.type = "text/javascript";
		s.async = !0;
		s.src = url;
		head.appendChild(s);
//		console.log(url);
	},

	// render just the first item in the climbs list. 
	renderFirst: function() {
		if (this.climbs.length>0) {
			this.climbs[0].render(this.containerID, true, true);
			Sendage.API.publish('toggle-to-sendlist.reloaded', true);
		}
	},
	
	// render just the last item in the climbs list. 
	renderLast: function() {
		if (this.climbs.length>0) {
			this.climbs[this.climbs.length-1].render(this.containerID, true);
			Sendage.API.publish('toggle-to-sendlist.reloaded', true);
		}
	},

	renderShowMore: function() {
		var me = this;
		var className = this.config.autoShowMore ? " class='auto'":"";
		$("#"+this.config.pageContainerId).html("<div"+className+"><img src='"+Sendage.Config.cdnver+"/img/wait_spinner.gif'/><label>Show More</label></div>")
		.find("label")
		.click(function(e){
			$(this).blur();
			me._handleShowMore();
		});
	},
	
	_handleShowMore: function() {
		var me = this;
		if  (!me.showingMore && me.pageState.index+me.pageState.size<me.climbs.length)
		{
			me.showingMore = true;
			var wait = $("#"+this.config.pageContainerId).find("img").css('visibility', 'visible');
			setTimeout(function(){
				me.pageState.index += me.pageState.size;
				me.render(true);
				me.showingMore = false;					
				wait.css('visibility', 'hidden');
			}, 400);
		}
		
	},	
	
	renderSort: function() {
		if (this.config.sortContainerId)
		{
			var e = $("#"+this.config.sortContainerId);
			var html = [];
			html.push("Sort: <select>");
			html.push("<option value=''></option>");
			html.push("<option value='day'>Day Sent</option>");
			html.push("<option value='name'>Name</option>");
			html.push("<option value='grade'>Grade</option>");
			html.push("<option value='rating'>Rating</option>");
			html.push("<option value='trophy'>Trophy</option>");
			html.push("</select><div class='wait-spinner-container sort'><img style='visibility:hidden;' src='"+Sendage.Config.cdnver+"/img/wait_spinner.gif'/></div>");
			e.html(html.join(""));
			
			var me = this;
			$("#"+this.config.sortContainerId+" select").change(function(){
				me._sortList($(this).val());
			});
			
			if (this.climbs.length == 0)
				e.hide();
		}		
	},
	
	renderFilter: function() {
		if (this.config.filterContainerId)
		{
			var e = $("#"+this.config.filterContainerId);
			var html = [];
			html.push("Filter: <select>");
			html.push("<option value='all'>All Climbs</option>");
			html.push("<option value='routes'>Only Routes</option>");
			html.push("<option value='boulders'>Only Boulders</option>");
			html.push("<option value='first_ascent'>First Ascents</option>");
			html.push("<option value='project'>Projects</option>");
			html.push("</select>");
			e.html(html.join(""));
			
			var me = this;
			$("#"+this.config.filterContainerId+" select").change(function(){
				if (!me.filterFunctionPersist)
					me.filterFunction = null;
				me.pageState.filter = $(this).val();
				me.pageState.index = 0;
				me.render();
			});			

			if (this.climbs.length == 0)
				e.hide();
		}
	},
	
	renderSearch: function() {
	
		this.searchTimeout = null;
	
		if (this.config.searchContainerId)
		{
			var e = $("#"+this.config.searchContainerId);
			if (!e || e.css("display")=="none") return;
			
			e.html("Filter: <input type='text' placeholder='Climb, Area or Grade' style='padding:6px;'/>");
			
			
			var me = this;

			$("#"+this.config.searchContainerId+" input").keyup(function(){

				if (me.searchTimeout)
					clearTimeout(me.searchTimeout);

				var searchVal = removeDiacritics($(this).val()).toLowerCase().replace(/\s/g,'').replace('+','\\+').replace("'",'');

				me.searchTimeout = setTimeout(function() {
					$(".wait-spinner-container.sort img").css("visibility", "visible");
					me.filterFunctionPersist = true;
					me.filterFunction = function(filter, data) {
						return (
								removeDiacritics(data.area.name).toLowerCase().replace(/\s/g, '').replace("'",'').match(searchVal) || 
								removeDiacritics(data.climb.name).toLowerCase().replace(/\s/g, '').replace("'",'').match(searchVal) || 
								data._getGrade(false).toLowerCase().match(searchVal) || 
								data._getGrade(false, true).toLowerCase().match(searchVal)
							) 
							&& (
								filter=="all" || 
								(filter=="routes" && data.isRoute()) || 
								(filter=="boulders" && data.isBoulder()) ||
								(filter=="first_ascent" && data.isFirstAscent())
							);
					};
					setTimeout(function() {
						me.pageState.index = 0;
						me.render();
						me.searchTimeout = null;
						$(".wait-spinner-container.sort img").css("visibility", "hidden");
					},20);
				}, 300);
			});			

			if (this.climbs.length == 0)
				e.hide();
		}
	},

	getClimbIDs: function() {
		var ids = [];
		for (var i=0; i<this.climbs.length; i++)
			ids.push(this.climbs[i].climb.id);
		return ids;
	},

	size: function() {
		return this.climbs.length;
	},
	
	_sortList: function(type) {
		var comparator = null;
		
		if (type != "")
		{
			switch (type)
			{
				case "day":													// Sort by Day desc
					comparator = function(a, b) {
						var d1 = a.user_climb.day ? a.user_climb.day.split("-") : [0,0,0];
						var d2 = b.user_climb.day ? b.user_climb.day.split("-") : [0,0,0];
						return (d2[0]*10000+d2[1]*100+d2[2]*1) - (d1[0]*10000+d1[1]*100+d1[2]*1);					
					};
					break;
				case "name":												// Sort by name asc
					comparator = function(a, b) {
						return a.climb.name > b.climb.name ? 1 : -1;					
					};
					break;
				case "grade":												// Sort by grade desc
					comparator = function(a, b) {
						var gradeA = a.user_climb&&a.user_climb.grade_id ? a.user_climb.grade_id : a.grade.id;
						var gradeB = b.user_climb&&b.user_climb.grade_id ? b.user_climb.grade_id : b.grade.id;
						return parseInt(gradeB,10) - parseInt(gradeA,10);
					};
					break;
				case "rating":												// Sort by rating desc
					comparator = function(a, b) {
						return parseInt(b.user_climb.rating ? b.user_climb.rating : 0,10) - parseInt(a.user_climb.rating ? a.user_climb.rating : 0,10);
					};
					break;
				case "trophy":												// Sort by trophy as onsight, flash, redpoint (desc)
					comparator = function(a, b) {
						var map = {"project":0, "redpoint":1, "flash":2, "onsight":3};
						return map[b.user_climb.type ? b.user_climb.type : "project"] - map[a.user_climb.type ? a.user_climb.type : "project"];  
					};
					break;
			}
			
			$(".wait-spinner-container.sort img").css("visibility", "visible");
			var me = this;
			setTimeout(function() { 
				me.climbs.sort(comparator);
				setTimeout(function(){
					me.pageState.index = 0;
					me.render();
				},10) 
				$(".wait-spinner-container.sort img").css("visibility", "hidden");
			}, 200);	
		}		
	},
	
	// can use this to find a climb. If return false, then climb is not in the list.
	// note: sequential search TODO: see todo for climbs[]
	_getClimbIndex: function(sendlist_climb) {
		for (var i=0; i<this.climbs.length; i++)
			if (this.climbs[i].climb.id == sendlist_climb.climb.id)
				return i;
		return false;
	},

	_updateBorderClass: function() {
		if (this.climbs.length == 0)
			this.element.removeClass("ui-sendlist-border");
		else	 if (!this.element.hasClass("ui-sendlist-border"))
			this.element.addClass("ui-sendlist-border");
	},

	_resized: function()
	{
		this._updateBorderClass();
		
		if (this.climbs.length == 0)
		{
			$("#"+this.config.sortContainerId).hide();
			$("#"+this.config.filterContainerId).hide();
		}
		else
		{
			$("#"+this.config.sortContainerId).show();
			$("#"+this.config.filterContainerId).show();			
		}
	},
	
	_getNewClimbData: function() {
		var data = [];

		for (var i=0; i<this.climbs.length; i++)
		{
			var sendlist_climb = this.climbs[i];
			data.push({
				climb_id: sendlist_climb.climb.id,
				user_climb_id: sendlist_climb.user_climb.id ? sendlist_climb.user_climb.id : null
			});
		}

		return data;
	},

	_throwResizedEvent: function() {
		this._resized();
		$(this).trigger("resized", this.climbs.length);
	},
	
	_throwNumSentEvent: function() {
		var data = {
			total: 0,
			project: 0,
			redpoint: 0,
			flash: 0,
			onsight: 0
		}
		var numSent = 0;
		for (var i=0; i<this.climbs.length; i++)
		{
			var c = this.climbs[i];
			if (c._isClimbed())
				data.total++;
			data[c.user_climb.type]++
		}
		$(this).trigger("numsent", data);
	},
		
	eof: null
};


function SendlistClimb(id, sendlist, climb, grade, area, user_climb, editable)
{
	this.liID = "sendlist-climb-"+climb.id;
	this.id = id;
	this.sendlist = sendlist;
	this.climb = climb;
	this.grade = grade;
	this.area = area;
	this.user_climb = user_climb || {};
	this.editable = editable;
}

SendlistClimb.prototype = {
	liID: "",
	id: null,
	sendlist: null,
	climb: null,
	area: null,
	user_climb: {},
	editable: false,
	ratingLabels: [
		"No Rating Available.",
		"Poor. Don't climb it.",
		"Not bad. Climb it when there's nothing better.", 
		"Pretty Good. Worth climbing.", 
		"Great! Definitely climb this.",
		"The BEST! Climb this or die."
	],

	render: function(containerID, slideDown, prepend) {
		slideDown = slideDown || false;
		prepend = prepend ||  false;
		
		if (slideDown)
		{
			if (prepend)
				$("<li id='"+this.liID+"'>"+this._toHTMLString()+"</li>").hide().prependTo("#"+containerID).slideDown();
			else
				$("<li id='"+this.liID+"'>"+this._toHTMLString()+"</li>").hide().appendTo("#"+containerID).slideDown();
			this._attachHandlers();
		}
		else
		{
			if (prepend)
				$("#"+containerID).prepend("<li id='"+this.liID+"'>"+this._toHTMLString()+"</li>");
			else
				$("#"+containerID).append("<li id='"+this.liID+"'>"+this._toHTMLString()+"</li>");
			this._attachHandlers();
		}
	},

	hide: function() {
		$("#"+this.liID).hide();
	},
	
	show: function() {
		$("#"+this.liID).show();
	},
	
	isRoute: function() {
		return this.climb.type=="sport" || this.climb.type=="trad";
	},
	
	isBoulder: function() {
		return this.climb.type=="boulder";
	},
	
	isFirstAscent: function() {
		// PHP >=5.4
		//return this.user_climb && this.user_climb.first_ascent && parseInt(this.user_climb.first_ascent);
		return this.user_climb && this.user_climb.first_ascent;
	},

	isInUserList: function() {
		return !this.id; 	// user lists don't have this.id set. 
	},

	// This will update the content within the <li> using the existing state
	rerender: function() {
		$("#"+this.liID).html(this._toHTMLString());
		this._attachHandlers();
	},

	_updateDialogUpdateButton: function() {
		var type = $("#sendage-dialog div.trophies.selected").attr("value");
		var label = !this._isClimbed() || type=='repeat' ? 'Save' : 'Edit';
 		$("#sendButtonUpdate").html('<span class="ui-button-text">'+ label +'</span>');
	},

	sent: function(editSend, autoSelectRepeat, onlyAllowRepeat) {
		if (editSend === undefined)
			editSend = false;
		var dialog = $("#sendage-dialog");
		var qqUploader;

		// 
		if (onlyAllowRepeat) {
			autoSelectRepeat = true;
		}


		if (dialog.length == 0)
		{
			$(document.body).append("<div id='sendage-dialog' style='display:none'></div>");
			dialog = $("#sendage-dialog");

			var html = [];

			//html.push("<h1 class='top' style='text-align:center;'>You Got SENDAGE!</h1>");
			html.push("<div style='margin-top: 5px;'>");

			html.push("<div class='trophy-selector'>");
				html.push('<div class="trophies trophy-count bordered" value="redpoint" title="It took you 2 or more tries to send">');
					html.push(Trophy.toHtml('redpoint'));
					html.push('<span class="highlight"><sub>redpoint</sub></span>');
				html.push('</div>');
				html.push('<div class="trophies trophy-count bordered" value="flash" title="You sent first try, but with beta">');
					html.push(Trophy.toHtml('flash'));
					html.push('<span class="highlight"><sub>flash</sub></span>');
				html.push('</div>');
				html.push('<div class="trophies trophy-count bordered" value="onsight" title="You sent first try, no beta">');
					html.push(Trophy.toHtml('onsight'));
					html.push('<span class="highlight"><sub>onsight</sub></span>');
				html.push('</div>');
				html.push('<span class="minor">');
					html.push('<div class="trophies trophy-count bordered selected" value="project" title="Track your projects.">');
						html.push(Trophy.toHtml('project'));
						html.push('<span class="highlight"><sub>project</sub></span>');
					html.push('</div>');
					html.push('<div class="trophies trophy-count bordered" value="repeat" title="You have sent this climb already and want to log it again.">');
						html.push(Trophy.toHtml('repeat'));
						html.push('<span class="highlight"><sub>repeat</sub></span>');
					html.push('</div>');
				html.push("</span>");
			html.push("</div>");
			

			html.push("<div style='margin-top:10px;'>");
			html.push("<div class='nonproject col rating-container'>");
				html.push("<div class='highlight' style='margin-bottom:5px;'>Rating:</div>");
				html.push("<div>"+this._getStarRatingEditableHTML(5, "star1", "star")+"</div>");
				html.push("<div class='' id='rating_error' style='display:none;'><sub>Please rate this send.</sub></div>");
			html.push("</div>");
			html.push("<div class='nonproject col grade-picker'>");
				html.push("<div class='highlight'>Suggest Grade:</div>");
				html.push("<div><select class='difficulty'><option value='-1'>Easy / Low-End</option><option value='0'>Solid</option><option value='1'>Hard / High-End</option></select> "+this._getGradeHTML("sport")+this._getGradeHTML("boulder")+"</div>");
			html.push("</div>");
			html.push("<div class='nonproject colRight last highlight' style='padding-top:22px;'>");
				html.push("<label title='A first ascent means you are the first person ever to send this climb – no one else has ever climbed it before you. It does not mean you sent it first try.'><input type='checkbox' id='sendage-dialog-fa-cb'/> First Ascent</label>");
			html.push("</div>");
			html.push("<div class='colbreak'></div></div>");
			
			html.push("<div class='day nonproject withrepeat clearfix' style='margin-top:10px;'>");
				html.push("<div class='col'>");
					html.push("<div class='highlight'>Date:</div>");
					html.push("<div><input type='hidden' id='sendage-dialog-datepicker-value'/><input type='text' id='sendage-dialog-datepicker'/> <label class='prompt nonproject'><input type='checkbox' id='sendage-dialog-date-cb'/> Not Sure</label></div>");
				html.push("</div>");
				//html.push("<div class='col'>");
				//html.push("<div class='highlight'>#Attempts:</div>");
				//html.push("<div><input type='text' size='3' /></div>");
				//html.push("</div>");
			html.push("</div>");

			html.push('<div id="sendlist_media_uploader" class="col"><div id="qq_sendlist_upload_button"><button>Upload a Photo of This Climb</button></div><div class="prompt indented"><sup class="supported">Supported Types: png, jpg, gif.&nbsp;&nbsp;Max: 2 mb</sup></div></div><div id="sendlist_media_upload_preview" class="colRight"></div><div class="colbreak"></div>');

			html.push("<div class='highlight' style='margin-top:10px;'>Comments:</div><div><textarea class='sendage-dialog-comment'></textarea></div>");

			html.push("<div class='highlight nonproject' style='margin-top:10px;'>Beta:</div><div class='nonproject'><textarea class='sendage-dialog-beta'></textarea></div>");

			if (FB.getUserID()) {
				var checked = Sendage.Config.isFacebookPublishingEnabled ? 'checked' : '';
				html.push("<div class='highlight nonproject' style='margin-top:10px;'><label><input type='checkbox' id='facebook-publish-cb' "+checked+"/> Share this send on Facebook</label></div>");
			}
			html.push("</div>");
			
			dialog.html(html.join(""));

			$("input.star[type=radio]").rating({
				callback: function(value) {
					if (value > 0)
						$("#rating_error").hide("fadeOut");
				}
			});
	
			var $mediaUploader = $("#sendlist_media_uploader");
			if ($mediaUploader.length)
			{
				var $mediaUploaderButton = $("#sendlist_media_uploader button");
				//$("#photo_upload_button").button();
				$mediaUploaderButton.button({
					icons: {
						primary: 'ui-icon-image'
					}
				});
				
				$("#qq_sendlist_upload_button").hover(function() {
					$mediaUploaderButton.addClass("ui-state-hover");
				}, function() {
					$mediaUploaderButton.removeClass("ui-state-hover");
				});
				
				var newUploadContainer;
				qqUploader = new qq.FileUploaderBasic({
					action: "/climb_media/upload/climb/"+this.climb.slug+"/"+this.climb.id,
					button: $("#qq_sendlist_upload_button")[0],
					multiple: false,
					onSubmit: function(id, fileName) {
						// show progress bar
						newUploadContainer = $("<div></div>").prependTo($("#sendlist_media_upload_preview")).addClass("sendage-media");
						newUploadContainer.append("<div class='sendage-media-item small inprogress'></div>");
					},				
					onComplete: function(id, fileName, data) {
						if (data && !data.error)
						{
							var href = data["ClimbMedia"].url;
							newUploadContainer.html("<div class='sendage-media-item small' style='background-image:url("+href.replace(/-full\./,'-sq.')+");'></div>");
						}
						else if (data.error)
						{
							newUploadContainer.remove();
							alert(data.error);
							
						}
					},
					
					// override to show messages with some nice UI
					showMessage: function(message) {
						//alert(message);
					}               
				}); 
			}		
				
	
			$("#sendage-dialog-datepicker").datepicker({
				disabled: true,
				changeMonth: true,
				changeYear: true,
				maxDate: "+0d",
				dateFormat: "DD, MM d, yy",
				altField: "#sendage-dialog-datepicker-value",
				altFormat: "yy-mm-dd",
				onSelect: function()	{
					$("#sendage-dialog-date-cb").attr("checked", false);	
				}
			});

			$("#sendage-dialog div.trophies")
			.hover(
				function() {		// mouse-in
					if (!$(this).hasClass("selected"))
						$(this).addClass("hover");
				},
				function() {		// mouse-out
					$(this).removeClass("hover");
				}
			) 
			.click(function() {
				$(this).removeClass("hover");
				var previousValue = $("#sendage-dialog div.trophies.selected").attr("value");
				$("#sendage-dialog div.trophies").removeClass("selected");	// remove selected from all trophy elements
				$(this).addClass("selected");				// then add selected to only this element

				var isProject = $(this).attr("value") == "project";
				var isRepeat = $(this).attr("value") == "repeat";
				
				if (isProject) {
					$(".nonproject")["hide"]("slideUp");					
				} else if (isRepeat) {
					$(".nonproject").not(".withrepeat")["hide"]("slideUp");					
					$(".withrepeat")["show"]("slideUp");					
				} else {
					$(".nonproject")["show"]("slideUp");
				}
				
				var sendlistClimb = dialog.dialog("option", "SendlistClimb");
				
				if (isRepeat) {
					$("textarea.sendage-dialog-comment").val("");
					$("#sendage-dialog-datepicker").datepicker("setDate", new Date());					
				} else if (previousValue == "repeat") {
					var uc = sendlistClimb.user_climb;
					var curVal = $.trim($("textarea.sendage-dialog-comment").val());
					
					if (uc) {
						if (uc.day && uc.day!="0000-00-00") {
							$("#sendage-dialog-datepicker").datepicker("setDate", parseDate(uc.day));
						}
						if (!curVal && uc.comments) {
							$("textarea.sendage-dialog-comment").val(uc.comments);	
						}
					}
				}
				
				
				sendlistClimb._updateDialogUpdateButton();
			});
			
			$("#sendage-dialog-date-cb").click(function() {
				if (this.checked)
					$("#sendage-dialog-datepicker").datepicker("setDate", null);
			});
			
			$("#sendage-dialog-fa-cb").click(function() {
				if (this.checked)
					$("#sendage-dialog .trophy-selector .trophy").addClass("first-ascent");
				else
					$("#sendage-dialog .trophy-selector .trophy").removeClass("first-ascent");
			});


			dialog.dialog({
				autoOpen: false,
				modal: true,
				resizable: false,
				width: 400,
				position: ["center", 50],
				open: function() {
					$("#sendage-dialog-datepicker").datepicker("enable");
					$("textarea.sendage-dialog-comment").focus();
					$("#sendlist_media_upload_preview").empty();
					var me = $(this).dialog("option", "SendlistClimb");
					qqUploader._handler._options.action = "/climb_media/upload/climb/"+me.climb.slug+"/"+me.climb.id;					
					if (editSend) {
						$('#facebook-publish-cb').attr('checked', false);
					} 
				},
				buttons: [{
					text: 'Cancel',
					click: function() {
						var me = $(this).dialog("option", "SendlistClimb");
						// undo the checkbox
						if (me.editable)
							$("#"+me.liID+" .ui-sendlist-climb-cb").attr("checked", false);
					
						$(this).dialog("close");
					}
				}, {
					id: 'sendButtonUpdate',
					text: 'Update',
					click: function() {
						var me = $(this).dialog("option", "SendlistClimb");

						var climbType = me.climb.type;
						if (climbType=="trad")
							climbType = "sport";

						var type = $("div.trophies.selected").attr("value");
						var senddate = $("#sendage-dialog-datepicker-value").val();
						var rating = $("input.star:radio:checked").val();
						if (rating === undefined)
							rating = 0;
						var comments = $("textarea.sendage-dialog-comment").val();
						var beta = $("textarea.sendage-dialog-beta").val();
						var grade = $("select.grade-"+climbType).val();
						var difficulty = $("select.difficulty").val();
						var firstAscent = $("#sendage-dialog-fa-cb").is(":checked")?1:0;
						
						if ($("#sendage-dialog-date-cb").is(":checked") && type != 'repeat') {
							senddate = "0000-00-00";
						}
							
						if (true)//rating>0 || type=="project")
						{
							$("#rating_error").hide();
							if (me.climb && me.climb.id) {
								var publishFB = $("#facebook-publish-cb").is(":checked");
								$.ajax({
									url: "/user_climbs/update/"+me.climb.id,
									type: "POST",
									timeout: 10000,
									data: {
										type: type,
										day: senddate,
										rating: rating,
										comments: comments,
										beta: beta,
										grade: grade,
										first_ascent: firstAscent,
										difficulty: difficulty,
										publish: publishFB
									},
									success: function(data) {
										if (data.response)
										{
											if (type == 'repeat') {
												Analytics.Sends.LogFinish.repeat();
												if (me && me.user_climb) {
													me.user_climb.repeats += 1;
													me.rerender();
												}
												
											} else {
												Analytics.Sends.LogFinish.complete();
												if (!me.id && type == "project" && false) {
													me.sendlist.remove(me);
												} else if (me.sendlist && me.sendlist.insert) {
													// We attempt to insert the climb anyways.
													me.user_climb = data.userClimb;
													if (me.sendlist.insert(me, 0)) {
														me.sendlist.renderFirst();
													} else {
														me.rerender();
													}
												} else if (me.updateClimbCallback) {
													me.updateClimbCallback(data.userClimb);
												}
													
												if (me.sendlist && me.sendlist._throwNumSentEvent) {
													me.sendlist._throwNumSentEvent();
												}

												if (publishFB && FB && type!='project' && type!='repeat') {
													// console.log(data);
													// console.log(me.climb);
													// console.log(me.area);
													var host = window.location.host;
													host = 'sendage.com';
													var name = me.climb.name+' - '+Sendlist.Grade.translate(data.userClimb.grade_id, me.climb.type);
													var verbpt = {'redpoint':'Redpointed','onsight':'Onsighted','flash':'Flashed'};
													//var caption = areaWithParents(me.area, ' > ', false);
													var rating = parseInt(data.userClimb.rating);
													var description = data.userClimb.comments;
													var trophy = data.userClimb.type;

													
													if (data.userClimb.first_ascent) {
														name = "FA: "+name;
														trophy = trophy+'-fa';
													}

													if (rating > 0) {
														//description = Array(rating+1).join("★")+Array(5-rating+1).join("")+' '+description;
													}

													var caption = verbpt[type];
													if (data.userClimb.day != '0000-00-00') {
														caption += ' on '+data.userClimb.day;
													}
													caption += (' @ '+areaWithParents(me.area, ' > ', false));

													// TODO. if iOS, the FB.ui doesn't open because touch event isn't part of this scope (ajax callback).
													//       so we could present user with a dialog for them to click okay to continue with posting to FB. 
													//var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

													FB.ui({
														method: 'feed',
														link: 'http://'+host+'/climb/'+me.climb.slug,
														name: name,
														//display: 'iframe',
														picture: 'http://'+host+'/img/trophy/'+trophy+'-200.jpg',
														caption: caption,
														description: description
													}, function(response){});
												}
											}

										}
										else if (!data.isLoggedIn)
											alert("You are not logged in!");
										else
											alert("An unknown error occured. Please report this bug.");
									}
								});
							}
							else
							{
								me.user_climb.day = senddate;
								me.user_climb.type = type;
								me.user_climb.rating = rating;
								me.user_climb.comments = comments;
								me.user_climb.beta = beta;
								me.user_climb.first_ascent = firstAscent;
								me.rerender();
							}
					
							$(this).dialog("close");							
						}
						else
						{
							$("#rating_error").show("fadeIn");							
						}
					}
				}]
			});

			
		}

		if (editSend) {
			Analytics.Sends.LogStart.edit();
		}

		// Deselect trophies
		$("div.trophies").removeClass("selected");
		$("#rating_error").hide();

		var type = this.climb.type=="trad"?"sport":this.climb.type;
		$("select.grade-sport,select.grade-boulder").hide();

		var simpleDisplayGrade = this._getGrade();
		var suggestedGradeId = editSend?this.user_climb.grade_id:this.climb.grade_id
		
		
		var gradeId = parseInt(this.climb.grade_id,10);
		var gradeList = Sendlist.Grade[(Sendage.Config.useFrenchGrades?"french_":"")+type];
		var range = {};
		for (var i=0; i<gradeList.length; i++)
		{
			if (simpleDisplayGrade == gradeList[i].grade)
			{
				range.min = parseInt(gradeList[Math.max(i-3,0)].id,10);
				range.max = parseInt(gradeList[Math.min(i+3,gradeList.length-1)].id,10);
				break;	
			}
		}
		
		// Show only +/- grades from the concensus grade
		$("select.grade-"+type).filterOptions(range, function(optionVal, range){
			var id = parseInt(optionVal, 10);
			return id>=range.min && id<=range.max;
		});
		
		// Set the concensus grade value on the appropriate <option>		
		$("select.grade-"+type).find('option').each(function() {
			var label = $(this).text();			
			if (label == simpleDisplayGrade)
			{
				$(this).val(suggestedGradeId);
			}							
		});
		

		$("select.grade-"+type).val(suggestedGradeId).show();
		$("select.difficulty").val(editSend?this.user_climb.grade_difficulty:0)

		var isProject = false;
		
		if (editSend)
		{
			var sd = this.user_climb;
			var day = sd.day ? parseDate(sd.day) : new Date();
			var isFA = sd.first_ascent;
			isProject = sd.type == "project";
			
			$("#sendage-dialog-date-cb").attr("checked",sd.day=="0000-00-00");
			$("#sendage-dialog-fa-cb").attr("checked", isFA);
			if (isFA) {
				$("#sendage-dialog .trophy-selector .trophy").addClass("first-ascent");
			} else {
				$("#sendage-dialog .trophy-selector .trophy").removeClass("first-ascent");
			}
			if (autoSelectRepeat) {
				$("div.trophies[value=repeat]").addClass("selected");	
				$("#sendage-dialog-datepicker").datepicker("setDate", new Date());
			} else {
				$("div.trophies[value="+sd.type+"]").addClass("selected");	
				$("#sendage-dialog-datepicker").datepicker("setDate", sd.day!="0000-00-00"?day:null);
			}
			$("textarea.sendage-dialog-comment").val(sd.comments?sd.comments:"");
			$("textarea.sendage-dialog-beta").val(sd.beta?sd.beta:"");
			$("input.star[type=radio]").rating("select", sd.rating>0?sd.rating-1:null);
		}
		else
		{
			var sd = this.user_climb;
			$("#sendage-dialog-date-cb").attr("checked", false);
			$("#sendage-dialog-fa-cb").attr("checked",false);
			$("#sendage-dialog .trophy-large").removeClass("fa");
			$("div.trophies[value="+(isProject?"project":"redpoint")+"]").addClass("selected");
			if (!$("#sendage-dialog-datepicker").datepicker("getDate")) {
				$("#sendage-dialog-datepicker").datepicker("setDate", new Date());
			}
			$("#sendage-dialog-datepicker").datepicker("disable");
			$("textarea.sendage-dialog-comment").val(sd && sd.comments?sd.comments:"");
			$("textarea.sendage-dialog-beta").val(sd && sd.beta?sd.beta:"");
			$("input.star[type=radio]").rating("select", null);
		}
		// This hides the datepicker modal
		$("#sendage-dialog-datepicker").datepicker("disable");

		dialog.dialog("option", "title", this.climb.name+" - "+simpleDisplayGrade);
		dialog.dialog("option", "SendlistClimb", this);

		if (autoSelectRepeat) {
			$(".nonproject").not(".withrepeat").hide();
			$(".withrepeat").show();
		} else if (isProject) {
			$(".nonproject").hide();
		} else {
			$(".nonproject").show();
		}
		
		if (onlyAllowRepeat) {
			$("#sendage-dialog .trophy-selector .trophy-count").not(".selected").hide();
		} else {
			$("#sendage-dialog .trophy-selector .trophy-count").show();
		}
		
		// only allow repeats for climbs that have been sent		
		$("div.trophies[value=repeat]")[this._isClimbed()?"show":"hide"]();
		
		
		this._updateDialogUpdateButton();

		dialog.dialog("open");
	},

	unsent: function() {
		var me = this;

		if (this.id)
		{
			$.ajax({
				url: "/user_climbs/remove/"+this.climb.id,
				timeout: 10000,
				success: function(data) {
					if (data.response)
					{
						me.user_climb = {};
						me.rerender();
					}
					else
						alert("failed");
				}
			});
		}
		else
		{
			this.user_climb = {};
			this.rerender();
		}
	},


	// TODO handle removing climb from pending list, sendlist, userlist
	remove: function() {
		var me = this;

		if (!this.isInUserList()) {

			Sendage.API.removeFromSendlist(me.sendlist.id, me.climb.id).done(function(data) {
				me._removeFromDOM();			
				me.sendlist._throwNumSentEvent();
			});

		} else if (me.editable && me.user_climb && me.user_climb.climb_id) {
			$.ajax({
				url: "/user_climbs/remove/"+me.user_climb.climb_id,
				timeout: 10000,
				success: function(data) {
					if (data.response)
					{
						me._removeFromDOM();			
						me.sendlist._throwNumSentEvent();		
					}
					else
						alert("failed");
				}
			});
		} 
	},
		
	_removeFromDOM: function() {
		$("#"+this.liID).slideUp(300, function(){$(this).remove();});
	},

	_toHTMLString: function() {
		var checkbox = "";
		var isClimbed = this._isClimbed();
		var isProject = this._isProject();
		var isUserClimb = isClimbed||isProject; 
		var isFA = this.user_climb.first_ascent;
								 
		var checkbox = isClimbed ? ("<div class='ui-sendlist-climb-trophy"+(this.editable?" editable":"")+"'>"+Trophy.toHtml(this.user_climb.type, isFA)+(this.editable?"<sup>edit</sup>":"")+"</div>") 
								 : (this.editable ? "<input class='ui-sendlist-climb-cb' type='checkbox'/>" : "");
								 
		var userRating = this.user_climb.rating;
		var rating = isClimbed && userRating>0 ? this._getStarRatingHTML(userRating) : "";

		var climb_name = (isFA?"FA: ":"")+"<a href='/climb/"+this.climb.slug+"'>"+this.climb.name+"</a>";
		var climb_date = isClimbed && this.user_climb.day!="0000-00-00" ? this.user_climb.day : "";
		
		if (climb_date && this.user_climb.repeats) {
			climb_date += ", <a href='/user_climbs/view/"+this.user_climb.id+"'>"+this.user_climb.repeats+(this.user_climb.repeats>1?" repeats":" repeat")+'</a>';
		}
		
		// TODO need a grade object to display
		var grade_suggestion = false && isUserClimb && this.climb.grade_id!=this.user_climb.grade_id ? "<i>I think this climb is </i>" : "";
		
		var areaList = areaWithParents(this.area, ' > ', true);
		var html = [];

		html.push("<div class='col trophy'>"+checkbox+"</div>");
		html.push("<div class='col info'>");
			html.push("<span class='ui-sendlist-climb-label'>"+climb_name+"</span><br/>");
			html.push("<span class='ui-sendlist-climb-location'>"+areaList+"</span>");
			if (climb_date) {
				html.push("<p><sub>"+climb_date+"</sub></p>");	
			}
		html.push("</div>");
		html.push("<div class='col grade'>"+this._getDisplayGrade()+"</div>");

		html.push("<div class='colRight sendlist-action-buttons'>");
			
			if ((this.editable && !this.sendlist.parent_id && (this.id || this.user_climb && this.user_climb.id)) || this.sendlist.config.isBeingCreated)
			{
				html.push("<div title='Remove this Send'><a class='sendlist-climb-remove' href='#'><i class='fa fa-times'></i></a></div>");
				//html.push("<div><button class='ui-sendlist-climb-remove'>Remove</button></div>");
				html.push("<div class='remove_option'></div>");
			}

// TODO March2016 Removing link to user_climbs/view (comments) for now
// 			if (isClimbed) {
// 				html.push("<div title='Comment on this Send'><a class='' href='/user_climbs/view/"+this.user_climb.id+"'><i class='fa fa-comment-o'></i></a></div>");
// //				if (this.commentCount === undefined) {
// //					this.sendlist.pendingCommentCounts.push(this.user_climb.id);
// //				}
// 			}
			// Only add this feature if the sendlist doesn't belong to the user. 
			if (!this.editable && this.climb.iClimbedThis!==undefined && this.climb.iClimbedThis==0) {
				//html.push("<button class='ui-sendlist-logsend'>Log This Climb as Sent</button>");
				html.push("<div title='Log this climb as sent'><a class='sendlist-logsend' href='#'><i class='fa fa-check-square-o'></i></a></div>");
			}	


			// if the list is my own or if I'm logged in. 
			// if (this.editable || this.climb.iClimbedThis!==undefined) {
			// if (this.isInUserList()) {
				// console.log(this.id);
				html.push('<div><i data-id="'+this.climb.id+'" class="fa fa-list-ul toggle-to-sendlist"></i></div>');
			// }
			
		html.push("</div>");


		html.push("<div class='rating colRight'>");
			html.push(rating);
		html.push("</div>");
				
		if (isUserClimb && this.user_climb.comments)
			html.push("<div class='col comments'><div>"+this.user_climb.comments+"</div><div>"+grade_suggestion+"</div></div>");


		html.push("<div class='colbreak'></div>");
			
		return html.join("");

	},
	
	_attachHandlers: function() {
		var me = this;

		if (this.editable)
		{
			$("#"+this.liID+" .ui-sendlist-climb-cb").click(function() {
				if (this.checked)
					me.sent();
				else					// shouldn't be called now, because we show a trophy instead of a checkbox when it's sent
					me.unsent();
			}); 		

			$("#"+this.liID+" .ui-sendlist-climb-trophy").click(function() {
				me.sent(true);
			});
		}
		
		$("#"+this.liID+" .sendlist-climb-remove")
		.click(function(e) {
			e.preventDefault();
			var button = $(this);
			if (button.data("clicked"))
				return;
				
			button.data("clicked", true);
			button.blur();
			$("#tiptip_holder").fadeOut();
			
			if (!me.sendlist.config.showRemoveConfirmation)
				me.sendlist.remove(me);
			else
			{
				var e = $(this).closest('.sendlist-action-buttons').find('.remove_option');
				e.empty();
				e.slideDown(200, function(){
					
					$("<div class='sendlist-delete-confirm' style='display:none;'><span class='prompt'><sub><b>Really?</b> <a class='yes' href='#'>Yes</a> | <a class='no' href='#'>No</a></sub></span></div>").appendTo(e).fadeIn();

					$("#"+me.liID+" .remove_option a").click(function(evt){
						evt.preventDefault();
						
						var a = $(this);
						
						if (!a.hasClass("processing"))
						{
							a.addClass("processing");
							
							if (a.hasClass("yes"))
								me.sendlist.remove(me);
							else
							{
								e.children().fadeOut(200, function(){
									e.slideUp();
									button.data("clicked", false);
									a.removeClass("processing");
								});
							}
						}
					});	

				});
			}
		});

		$("#"+this.liID+" .sendlist-logsend").click(function(e) {
			e.preventDefault();			
			var sendlistClimb = new SendlistClimb(null, {config:{}}, me.climb, me.grade, me.area, null, false);
			var btn = $(this);
			sendlistClimb.updateClimbCallback = function(userClimb) {
				btn.fadeOut();
			};
			sendlistClimb.sent(false,false);
		});

	},

	_isClimbed: function() {
		return this.user_climb!=null && this.user_climb.type && this.user_climb.type!="project";
	},
	
	_isProject: function() {
		return this.user_climb!=null && this.user_climb.type && this.user_climb.type=="project";
	},

	_getDisplayGrade: function() {
		var isClimbed = this._isClimbed();
		var grade = this._getGrade();
		var displayGrade = grade;
		var concensusGrade = this._getGrade(true);
		var title = this._getDifficultyTitle(grade);
		
		if (isClimbed && displayGrade != concensusGrade)
		{
			displayGrade = "<i>"+displayGrade+"</i>";
			title = title+"Concensus grade is "+this._getGrade(true)+".";
		}
			
		return "<span class='ui-sendlist-climb-grade' title='"+title+"'>"+displayGrade+this._getDifficulty()+"</span>";
		
	},

	// forceClimb is true if you want the concensus grade
	_getGrade: function(forceClimb, useFrench) {
		forceClimb = forceClimb || false;
		// We use the local useFrench variable because the f() caller may want to explicity request a french grade even if settings are otherwise.
		var grade = this._isClimbed()&&this.user_climb.Grade&&!forceClimb?this.user_climb.Grade:this.grade;
		return Sendlist.Grade.translate(grade.id, this.climb.type, useFrench);
	},

	_getDifficulty: function() {
		var d = this._isClimbed() ? this.user_climb.grade_difficulty : 0;  
		return d!=0 ? ("<sup class='prompt'>"+(d>0?" &uarr;":" &darr;")+"</sup>") : "";
	},
	
	_getDifficultyTitle: function(grade) {
		var d = this._isClimbed() ? this.user_climb.grade_difficulty : 0;  
		return d!=0 ? (d>0?"Hard ":"Easy ")+grade+". " : "";		
	},

	_getStarRatingEditableHTML: function(numStars, name, className) {
		var labels = this.ratingLabels;
		name = name || "star-rating";
		className = className || "";
		var html = [];
		for (var i=1; i<=numStars; i++)
			html.push("<input type='radio' name='"+name+"' class='"+className+"' value='"+i+"' title=\""+labels[i]+"\"/>");
		return html.join("")+'<div class="colbreak"></div>';
	},
	
	_getStarRatingHTML: function(rating) {
		var labels = this.ratingLabels;
		var html = [];
		html.push('<div title="'+labels[rating]+'" class="fa-star-rating">');
		rating = parseInt(rating);
		if (rating) {
			var star = '<i class="fa fa-star"></i>';
			html.push('<span class="on">'+Array(rating+1).join(star)+'</span>'+Array(6-rating).join(star));
		}
		html.push('<div class="colbreak"></div></div>');
		return html.join("");
	},
	
	_getGradeHTML: function(type) {
		var html = ["<select class='grade-"+type+"' style='display:none;'>"];
		
		if (Sendage.Config.useFrenchGrades)
			type = "french_"+type;
		
		for (var i=0; i<Sendlist.Grade[type].length; i++)
		{
			var g = Sendlist.Grade[type][i];
			html.push("<option value='"+g.id+"'>"+g.grade+"</option>");
		}
		html.push("</select>");
		return html.join("");
	},
		
	eof: null
};

var DISQUSWIDGETS = {
	displayCount: function(data) {
//		console.log(data);
		if (data && data.counts) {
			for (var i=0; i<data.counts.length; i++) {
				var count = data.counts[i];
				if (count.comments>0)
					$("#userclimb"+count.uid).addClass("has-comments");
			}
		}
	}
}
