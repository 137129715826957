var Analytics = (function() {
	
	var Categories = {
		Tours: {
			name: 'Tours',
			actions: {
				User: {
					name: 'User',
					labels: {
						start: {
							name: 'Start',
							weight: 0
						},
						finish: {
							name: 'Finish',
							weight: 0
						}
					}
				},
				Sendlist: {
					name: 'Sendlist',
					labels: {
						start: {
							name: 'Start',
							weight: 0
						},
						finish: {
							name: 'Finish',
							weight: 0
						}
					}
				}
			}
		},
		
		Sendlists: {
			name: 'Sendlists',
			actions: {
				Create: {
					name: 'Create',
					labels: {
						start: {
							name: 'Start',
							weight: 0
						},
						finish: {
							name: 'Finish',
							weight: 0
						}
						
					}
				}
			}
		},
		
		Sends: {
			name: 'Sends',
			actions: {
				LogStart: {
					name: 'LogStart',
					labels: {
						profile: {
							name: 'FromProfilePage',
							weight: 0
						},
						climb: {
							name: 'FromClimbPage',
							weight: 0
						},
						list: {
							name: 'FromSendlist',
							weight: 0
						},
						edit:  {
							name: 'Edit',
							weight: 0
						}
					}
				},
				LogFinish: {
					name: 'LogFinish',
					labels: {
						complete: {
							name: 'Complete',
							weight: 0
						},
						repeat: {
							name: 'Repeat',
							weight: 0
						}
					}
				}
			}
		}
	};

	var api = {};

	for (var Category in Categories) {
		var CategoryName = Categories[Category].name;
		var Actions = Categories[Category].actions;
		api[Category] = {};
		
		for (var Action in Actions) {
			var ActionName = Actions[Action].name;
			var Labels = Actions[Action].labels;
			api[Category][Action] = {};
			
			for (var Label in Labels) {
				var LabelName = Labels[Label].name;
				var defaultWeight = Labels[Label].weight;
			
				api[Category][Action][Label] = (function(cat,act,lab,defaultWeight) {
					return function(weight) {
						weight = weight || defaultWeight;
						if (Analytics.testing) {
							console.log(cat+ '/'+ act+ '/'+ lab+ '/'+ weight);
						} else {
							_sendageTrackEvent(cat, act, lab, weight);
						}
					}
				})(CategoryName, ActionName, LabelName, defaultWeight);
			}
			
		}
	}

	return api;	

})();

//Analytics.testing = true;
Analytics.test = function() {
	Analytics.testing = true;
	console.log('----- Testing Analytics      Start -----');
	for (var i in Analytics) {
		for (var j in Analytics[i]) {
			for (var k in Analytics[i][j]) {
				Analytics[i][j][k]();
			}
		}
	}
	console.log('----- Testing Analytics   Finished -----');
	Analytics.testing = false;
}
