Sendage.JQUI = {};

(function($) {
	var JQUI = Sendage.JQUI;

	JQUI.acAreas = function($e, onSelect, onSearch, onComplete, showId) {
		$e.autocomplete({
			source: function(request, response) {
				Sendage.API.acAreas(request.term).done(function(data) {
					if (onComplete) {
						onComplete.call(this, data);
					}
					response($.map(data, function(item) {
						item.label = item.Area.name;
						item.value = item.Area.name;
						return item;
					}));					
				});
			},
			minLength: 1,
			select: onSelect ? onSelect : function(){},
			search: onSearch ? onSearch : function(){}
		}).data("autocomplete")._renderItem = function( ul, item ) {
			var areas = [];
			for (var i=0; i<item.Area.parents.length; i++) {
				var parent = item.Area.parents[i];
				areas.push(parent.name);
			}
			if (showId) {
				areas.push(item.Area.name+' ('+item.Area.id+')');
			} else {
				areas.push(item.Area.name);	
			}
			

			return $( "<li></li>" )
				.data( "item.autocomplete", item )
				.append( "<a>"+ '<div class="area">'+areas.join(' > ')+'</div>' + "</a>" )
				.appendTo( ul );
		};
		
	}
	
	JQUI.acClimbs = function($e, inAreas, excludeClimbId, onSelect, onSearch, onComplete) {
		$e.autocomplete({
			source: function(request, response) {
				var areas = typeof inAreas == 'function' ? inAreas.call() : inAreas;
				
				Sendage.API.acClimbs(request.term, areas, excludeClimbId).done(function(data) {
					if (onComplete) {
						onComplete.call(this, data);
					}		
					response($.map(data, function(item) {
						var grade = Sendlist.Grade.translate(item.Climb.grade_id, item.Climb.type);
						label = "<strong>"+item.Climb.name+"</strong> - "+grade+", <i>"+item.Area.name+"</i>";
						value = item.Climb.name;
						item.label = label;
						item.value = value;
						return item;
					}));	
				});
			},
			minLength: 1,
			select: onSelect ? onSelect : function(){},
			search: onSearch ? onSearch : function(){}
		}).data("autocomplete")._renderItem = function( ul, item ) {
			var areas = [];
			for (var i=0; i<item.Area.parents.length; i++) {
				areas.push(item.Area.parents[i].name);
			}
			areas.push(item.Area.name);
		
			return $( "<li></li>" )
				.data( "item.autocomplete", item )
				.append( "<a>"+ '<span class="climb">'+item.Climb.name+'</span><span class="grade">, '+Sendlist.Grade.translate(item.Climb.grade_id,item.Climb.type,false)+'</span> <span class="num-sends">&ndash; '+item.Climb.sends+' sends</span><div class="area">'+areas.join(' > ')+'</div>' + "</a>" )
				.appendTo( ul );
		};
		
	}
	
})(jQuery);