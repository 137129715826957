(function($) {
	if (Sendage.Config.isDebug) {
		Analytics.testing = true;	
	}

	$('.protocol-anchor').on('click', function(e) {
		var a = $(this);
		var protocolLink = $.trim(a.data("link"));
		if (protocolLink && (/Android/i.test(navigator.userAgent) || /iPhone|iPad|iPod/i.test(navigator.userAgent))) {
			e.preventDefault();
			var link = a.attr("href");	
			var clickedAt = +new Date;
			var timeout = setTimeout(function() {
				var timedoutAt = +new Date;
				if (timedoutAt - clickedAt <= 1500) {
					window.location = link;
				}
			}, 1000);
			window.location = protocolLink;				
		}	
	});

	if (!Sendage.Config.isMobile) {
		// Generic tooltips for all title attributes
		$("[title]").live("mouseover", function() {
			var $this = $(this);
			$this.die("mouseover");
			$this.tipTip({delay:50, maxWidth:"300px", defaultPosition:"top"}).removeAttr("title").trigger("mouseover");
		});
	}
		
	// search button on home page
	$("#user_panel button.search").button({
		text: false,
		icons: { primary: "ui-icon-search" }
	});
	
	// buttons in general
	$("a.make-button").each(function() {
		var icon = $(this).attr("data-icon");
		if (icon) {
			$(this).button({icons: { primary: icon}});
		} else {
			$(this).button();
		}
	});


	// Welcome banner for non-loggedin users
	if (!Sendage.Config.user_id) {
		$(".welcome-banner-dots a").click(function(e) {
			e.preventDefault();
			$(".welcome-banner").stop(true);
			$(".welcome-banner-dots a").removeClass("active");
			var sel = $(this).attr("class");
			$(this).addClass("active");
			$(".welcome-banner").css("opacity",null).removeClass("intro plan list accomplish share").addClass(sel);
		});

		function do_welcome_banner() {
			dot("intro");
			$(".welcome-banner").addClass("intro").delay(20)
				.fadeIn(1000).delay(10000).fadeOut(2000,function(){$(".welcome-banner-dots").fadeIn(); $(this).removeClass("intro").addClass("plan");dot("plan");}).delay(20)
				.fadeIn(1000).delay(7000).fadeOut(2000,function(){$(this).removeClass("plan").addClass("list");dot("list");}).delay(20)
				.fadeIn(1000).delay(7000).fadeOut(2000,function(){$(this).removeClass("list").addClass("accomplish");dot("accomplish");}).delay(20)
				.fadeIn(1000).delay(7000).fadeOut(2000,function(){$(this).removeClass("accomplish").addClass("share");dot("share");}).delay(20)
				.fadeIn(1000).delay(7000).fadeOut(2000,function(){$(this).removeClass("share"); setTimeout(do_welcome_banner,20)});			
		}

		function dot(sel) {
			$(".welcome-banner-dots a").removeClass("active");
			$(".welcome-banner-dots a."+sel).addClass("active");
		}
	
		do_welcome_banner();
	}

	// Sendage Ad Rotationg
	var ads = $(".sidebar-ad-container .sidebar-ad");
	var cur = 0;
	if (ads.length>1) {
		setInterval(function() {
			ads.removeClass("active");
			$(ads[++cur % ads.length]).addClass("active");
		}, 9000);
	}

	function isViewingList(id) {
		return $('#sendlist').attr('sendlist_id') == id;
	}

	var editingPanelTransform = [{
		'tag': 'span',
		'class': 'status',
		'children': [{
			'tag': 'i',
			'class': 'fa fa-list-ul'
		}, {
			'tag': 'span',
			'html': '${climbIds.length}'
		}]
	}, {
		'tag': 'a',
		'class': 'link-to',
		'href': '/sendlists/view/${uniqid}',
		'html': function() {
			return this.pending ? 'New List' : this.name;
		}
	}, {
		'tag': 'span',
		'class': 'actions',
		'children': [{
			'tag': 'i',
			'class': 'fa fa-check-circle',
			'title': 'Done editing list',
			'onclick': function(e) {
				if (e.obj.pending) {
					window.location = '/sendlists/view/'+e.obj.uniqid;
				} else {
					Sendage.API.editSendlistComplete().done(function() {
						if (!isViewingList(e.obj.id)) {
							window.location = '/sendlists/view/'+e.obj.uniqid;
						}
					});
				}
			}

		}, {
			'tag': 'i',
			'title': 'Cancel new list',
			'class': function() {
				var c = 'fa fa-times';
				if (!this.pending) {
					c += ' unavailable';
				}
				return c;
			},
			'onclick': function(e) {
				if (e.obj.pending) {
					Sendage.API.deleteSendlist(e.obj.id).done(function() {
						if (isViewingList(e.obj.id)) {
							window.location = '/users/view/'+e.obj.user_id;
						}
					});
				}
			}
		}, {
			'tag': 'a',
			'class': 'fa fa-question',
			'target': '_blank',
			'title': 'Teach me how to create or edit a sendlist',
			'href': 'http://sendage.com/community/forums/topic/create-manage-sendlists/'
		}]
	}];


	$('body').on('click', '.toggle-to-sendlist', function() {
		var e = $(this);
		var climbId = e.data('id');
		var api = e.hasClass('added') ? 'removeFromSendlist' : 'addToSendlist';
		Sendage.API[api](0, climbId);
		e.toggleClass('added');
	});

	$('.create-new-sendlist').on('click', function(e) {
		e.preventDefault();	
		if (Sendage.Config.user_id) {
			$('body').addClass('new-list-clicked');
			Sendage.API.editSendlist(0);
		} else {
			window.location = '/login';
		}
	});

	Sendage.API.subscribe('toggle-to-sendlist.reloaded', function(evt) {
		// console.log(evt);
		Sendage.API.getEditingSendlist().done(function(sendlist) {
			$('.toggle-to-sendlist').each(function() {
				var climbId = $(this).data('id');
				var strClimbId = climbId+''; // sendlist.climbIds  are all strings. indexOf seems to match types. make sure climbId is also a string. I know this sucks. 
				var isAdded = sendlist && sendlist.climbIds && sendlist.climbIds.indexOf(strClimbId) >= 0 ? true : false;	 // required a proper boolean, not just a truthy value for $.toggleClass
				$(this).toggleClass('added', isAdded);
			});		
		});
	});


	Sendage.API.subscribe('sendlist.editing.changed', function(evt, sendlist) {
		// console.log(evt);
		// console.log(sendlist);
		var panel = $('#sendlist-editing-panel');

		if (!sendlist || !sendlist.climbIds || !sendlist.editing || sendlist.parent_id) {
			$('body').removeClass('editing-sendlist pending-sendlist viewing-editing-sendlist new-list-clicked');
			panel.remove();
			// bug fix to hide tooltips
			$('#tiptip_holder').hide();
		} else {
			$('body').addClass('editing-sendlist');

			// check if we're viewing the list we're actually editing
			$('body').toggleClass('viewing-editing-sendlist', sendlist.id == $('#sendlist').attr('sendlist_id'));
			$('body').toggleClass('pending-sendlist', sendlist.pending);

			// if (!panel.length) {
			// 	panel = $('<div id="sendlist-editing-panel"/>').appendTo($('body'));
			// }

			// panel.empty().json2html(sendlist, editingPanelTransform);
		}

		// if editing a cloned list, let's still see name/description fields.
		if (sendlist.parent_id && sendlist && sendlist.climbIds && sendlist.editing) {
			$('body').toggleClass('viewing-editing-sendlist', sendlist.id == $('#sendlist').attr('sendlist_id'));			
		}

		// This should sync toggle state when switching between editing a list to creating a new list. 
		// Downside is that sometimes, on page load, it will fire twice.
		Sendage.API.publish('toggle-to-sendlist.reloaded', {});
	});

	Sendage.API.subscribe('sendlist.editing.climbs.changed', function(evt, sendlist) {
		if (sendlist && sendlist.editing) {
			var panel = $('#sendlist-editing-panel');
			if (!panel.length) {
				panel = $('<div id="sendlist-editing-panel"/>').appendTo($('body'));
				setTimeout(function() {
					panel.addClass('just-added');	// for css transition effects
				}, 100);
			}
			panel.empty().json2html(sendlist, editingPanelTransform);
		}
	});

	if (Sendage.Config.user_id) {
		// fetch the sendlist that's being edited which throws events for interested UI to handle
		Sendage.API.getEditingSendlist().done(function(sendlist) {
			// console.log('got the list');
			Sendage.API.publish('sendlist.editing.changed', sendlist);
			Sendage.API.publish('sendlist.editing.climbs.changed', sendlist);
		});
	}

})(jQuery);